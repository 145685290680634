const getLocale = (locale: string): Promise<any> => {
    try {
        return import(
            /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
            `../locales/${locale}/messages.js`).catch(() => getLocale('en-US'))
    } catch (e) {
        return getLocale('en-US')
    }
}

export default getLocale

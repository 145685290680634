import { graphql } from 'relay-runtime'


const query = graphql`
    query AppQuery {
        viewer {
            id
            firstName
            lastName
            email
            metadata
            profileImage {
                thumbImage
            }
            forceForPass
            preferences {
                dateFormat
                language
                timeFormat
                timezone
                uiInfo
            }
            userRoles {
                role {
                    levelDetails {
                        ... on Location {
                            id
                        }
                        ... on Company {
                            id
                        }
                        ... on Group {
                            id
                        }
                        ... on Employee {
                            id
                        }
                    }
                    levelId
                    roleId
                    role {
                        id
                        roleName
                        isDefault
                        priority
                        level
                        moduleRoles {
                            name
                        }
                    }
                }
            }
            groups {
                id
                name
                groupSettings {
                    id
                    adminCss
                    metadata
                    hasExtendedFields
                }
                companies {
                    id
                    title
                    displayName
                    profession
                    active
                    customCompanyId
                    preference {
                        currency
                        dateFormat
                        language
                        timeFormat
                        timezone
                        uiInfo
                    }
                    metadata
                    gallery {
                        default {
                            thumbImage
                        }
                    }
                    address {
                        country
                    }
                    companySettings {
                        id
                        navMenus
                        aliases(locale: "en-US")
                        customization {
                            disableApps
                            disableStaffBooking
                            displayIntakeFormField
                        }
                    }
                    roleLevelCustomization {
                        disableApps
                        readonlyApps
                    }
                    apps {
                        id
                        appTypeId
                        name
                        active
                        serviceModules
                    }
                    slugObject {
                        id
                        slugType
                        slugValue
                    }
                    address {
                        country
                        latitude
                        locality
                        longitude
                        postalCode
                        region
                        streetAddress
                    }
                    telephones
                    description
                    gallery {
                        default {
                            largeImage
                            thumbImage
                        }
                        images {
                            largeImage
                            thumbImage
                        }
                    }
                    emails
                    locations(first: 500) {
                        edges {
                            node {
                                id
                                name
                                customLocationId
                                preference {
                                    currency
                                    dateFormat
                                    language
                                    timeFormat
                                    timezone
                                    uiInfo
                                }
                                slugObject {
                                    id
                                    slugType
                                    slugValue
                                }
                                address {
                                    country
                                    latitude
                                    locality
                                    longitude
                                    postalCode
                                    region
                                    streetAddress
                                }
                                telephones
                                description
                                gallery {
                                    default {
                                        largeImage
                                        thumbImage
                                    }
                                    images {
                                        largeImage
                                        thumbImage
                                    }
                                }
                                active
                                metadata
                                appointmentBookingFlowSetting {
                                    id
                                    enableTierBasedStaffPrioritization
                                    tierEmployeeAllocation
                                }
                            }
                        }
                    }
                    licenseWallet {
                        license {
                            moduleLicenses {
                                enabled
                                freeLimit
                                licenseLimit
                                maxAddonLimit
                                maxLimit
                                moduleName
                                paidAddonLimit
                                restrictionSlugs
                                restrictionType
                                rpcs
                                slug
                                totalLimit
                            }
                        }
                    }
                }
            }
            tokenValidTill
        }
        wizardPosition {
            wizardPosition
        }
    }
`

export default query
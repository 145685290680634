import loadable from '@loadable/component'
import { Fade, LinearProgress, Theme } from '@material-ui/core'
import { useConfig } from '@saastack/core'
import { PubSub } from '@saastack/pubsub'
import React, { FC, memo, useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/styles'

const CrispChat = loadable(() => import('@appointy/crisp/CrispChat'))

const useStyles = makeStyles(({ zIndex }: Theme) => ({
    progress: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: zIndex.tooltip + 1,
        pointerEvents: 'none',
    },
}))

const CommonComponents: FC = memo(() => {
    const { activeApps, group, groupId, viewer, companyId, locationId, employeeId } = useConfig()
    const classes = useStyles()
    const [loading, setLoading] = useState(0)
    const timerRef = useRef<any>()

    const roleID = viewer?.userRoles?.role?.find(({ levelId }) =>
        [groupId, companyId, locationId, employeeId].includes(levelId)
    )?.roleId

    useEffect(() => {
        const subId = PubSub.subscribe('app/loading', (payload) => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            setTimeout(() => {
                setLoading(0)
            }, 1000)
            setLoading((count) => Math.max(payload ? count + 1 : count - 1, 0))
        })
        return () => PubSub.unsubscribe(subId)
    }, [])
    useEffect(() => {
        document.body.setAttribute('data-roleid', `${companyId}/${roleID}`)
    }, [companyId])
    const isSupportEnabled = activeApps?.includes('Appointy Support')
    const customCss = group?.groupSettings?.adminCss
    return (
        <>
            <Fade in={loading > 0}>
                <LinearProgress variant="indeterminate" className={classes.progress} />
            </Fade>
            {customCss && (
                <Helmet>
                    <style id="custom-css">{customCss}</style>
                </Helmet>
            )}
            {isSupportEnabled && <CrispChat websiteId={process.env.REACT_APP_CRISP_WEBSITE_ID!} />}
        </>
    )
})

export default CommonComponents

import { useEffect } from 'react'
import { loadScript } from '@saastack/utils/Script'

const useAnalytics = () => {
    const tagManagerContainerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID
    const analyticsId = process.env.REACT_APP_GOOGLE_ANALAYTICS_ID

    useEffect(() => {
        if (tagManagerContainerId) {
            const snippet = `<script> var dataLayer = []; </script> <!-- Google Tag Manager --> <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${tagManagerContainerId}');</script> <!-- End Google Tag Manager -->  <!-- Google Tag Manager (noscript) --> <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerContainerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> <!-- End Google Tag Manager (noscript) -->`
            loadScript('google-tag-manager', snippet)
        }
    }, [tagManagerContainerId])

    useEffect(() => {
        if (analyticsId) {
            const snippet = `<!-- Google Analytics --> <script> (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', '${analyticsId}', 'auto'); ga('send', 'pageview'); </script> <!-- End Google Analytics -->`
            loadScript('google-analytics', snippet)
        }
    }, [analyticsId])
}

export default useAnalytics

import ReactDOM from 'react-dom'
import configure from '@saastack/core/configure'
import App from './App'


const defaultApps = [
    'Companies',
    'Locations',
    'Employees',
    'Apps',
    'Departments',
    'Designations',
    'MySpace',
    'User',
    'RolesNRights',
    'Billing',
    'ShortUrls',
    'StartGuide',
    'Addons',
    'ActivityLogs',
    'Aliases',
    'Notifications',
    'NotificationsApp',
    'Slugs',
    'CustomerPortal',
    'Categories',
    'Transactions',
    ...(Boolean(process.env.REACT_APP_ENABLE_GCANADA) ? ['GoogleCanada'] : []),
    ...(Boolean(process.env.REACT_APP_ENABLE_FEEDBACK) ? ['Feedbacks'] : []),
]
configure({ defaultApps })

const rootEl = document.getElementById('root')

ReactDOM.render(<App />, rootEl)

import { FC } from 'react'
import loadable from '@loadable/component'
import isAuthenticated from '@saastack/auth/utils/isAuthenticated'
import TemplateLoader from '@saastack/core/TemplateLoader'
import { Parents, TemplateProps } from '@saastack/core/types'
import { PubSub } from '@saastack/pubsub'
import { useQuery, withEnvironment } from '@saastack/relay'
import { loginUrl, parseId, store } from '@saastack/utils'
import { AppQuery } from './__generated__/AppQuery.graphql'
import useAnalytics from './hooks/useAnalytics'
import preloader from './preloader.gif'
import getLocale from './utils/getLocale'
import './styles.scss'
import urlJoin from 'proper-url-join'
import { validate } from 'uuid'
import query from './AppQuery'
import CommonComponents from './Common'

const Loading: FC = () => (
    <div className="main-loader">
        <img alt="" src={preloader} />
    </div>
)

const AuthPage = loadable<TemplateProps>(() => import('@saastack/auth/pages/AuthPage'))
const LoggedInApp = loadable(() => import('./LoggedInApp'), {
    fallback: <Loading />,
})

const templateLoaderProps = {
    logoUrl: process.env.REACT_APP_LOGO_URL,
    baseUrl: process.env.PUBLIC_URL,
    getLocale,
    admin: true,
}

const App: FC = () => {
    useAnalytics()
    const props = useQuery<AppQuery>(query)
    if (props.loading || ((window as any).isRedirecting && props.error)) {
        return <Loading />
    }
    if (!isAuthenticated(props)) {
        return (
            <TemplateLoader
                {...templateLoaderProps}
                logoUrl={process.env.REACT_APP_AUTH_LOGO_URL}
                baseUrl="/account"
                template={AuthPage}
            >
                <CommonComponents />
            </TemplateLoader>
        )
    }
    return <LoggedInApp {...props} />
}

export default withEnvironment({
    graphqlUrl: process.env.REACT_APP_GRAPHQL_URL,
    middlewares: [
        (next: any) => async (req: any) => {
            PubSub.publish('app/loading', true)
            const parents: Parents = store.get('parents', {
                groupId: '',
                companyId: '',
                locationId: '',
                userId: '',
            })
            let code = ''
            if (new URLSearchParams(window.location.search).has('code')) {
                const queryCode = new URLSearchParams(window.location.search).get('code') ?? ''
                if (validate(queryCode)) {
                    code = queryCode
                }
            }
            const queryId = (() => {
                try {
                    const body = JSON.parse(req.fetchOpts.body as string)
                    return body.id
                } catch (e) {}
                return ''
            })()
            req.fetchOpts.url = urlJoin(req.fetchOpts.url, {
                query: {
                    groupId:
                        parents.groupId ||
                        parseId(parents.companyId).parent ||
                        parseId(parseId(parents.locationId).parent).parent,
                    companyId:
                        parseId(parents.companyId).entityId || parseId(parents.locationId).parent,
                    locationId: parseId(parents.locationId).entityId,
                    userId: parents.userId ?? '',
                    queryId,
                    ...(!!code && { code }),
                },
            })
            const res: any = await next(req)
            const error = res.errors?.[0] as any
            if (error?.extensions?.code === 'Unauthenticated') {
                if (
                    !window.location.pathname.includes('account') &&
                    !(window as any).isRedirecting
                ) {
                    ;(window as any).isRedirecting = true
                    window.location.href = loginUrl()
                }
            }
            PubSub.publish('app/loading', false)
            return res
        },
    ],
})(App)
